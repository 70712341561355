import React, { useContext } from "react";
import "../HomeBody/homeBody.css";

// Types
import { iSponsors } from "../../Types/generalDataTypes";

// Global Context
import { iGlobalValues, iGlobalFts } from "../../Types/ContextGlobalVars";
import { ContextGlobalVars } from "../../Context/ContextGlobalVars";
import { ContextGlobalFts } from "../../Context/ContextGlobalFts";

// Icons
import { RiHeartFill, RiHeartLine } from "react-icons/ri";

// Functions
import { AddSponsorToFavorites } from "../../utils/generalUtils";

type iSponsorProp = {
  sponsor: iSponsors;
};

const DataTable = ({ sponsor }: iSponsorProp): JSX.Element => {
  const contextValues = useContext<Partial<iGlobalValues>>(ContextGlobalVars);
  const {
    userFavorites,
    setSelectedSponsor,
    setActivePagination,
    setSelectedEleInfo,
    setActiveComponent,
  } = contextValues as iGlobalValues;
  const contextFts = useContext<Partial<iGlobalFts>>(ContextGlobalFts);
  const { fetchUserFavorites } = contextFts as iGlobalFts;

  const videoWeight = 0.5; // Weight for the number of sponsored videos
  const favoriteWeight = 0.3; // Weight for user favorites (if available)

  // Function to calculate popularity index for each sponsor
  const calculatePopularityIndex = (sponsor: iSponsors) => {
    const { num_of_sponsored_videos } = sponsor;
    const numFavorites =
      userFavorites?.userFavoriteSponsors?.filter(
        (fav) => fav === sponsor.sponsor_id
      ).length || 0;

    // Calculate the index using weights
    const popularityIndex =
      num_of_sponsored_videos * videoWeight + numFavorites * favoriteWeight;

    return popularityIndex.toFixed(1); // One decimal for display
  };

  const handleAddFavorite = async (sponsor_id: string) => {
    await AddSponsorToFavorites(sponsor_id, fetchUserFavorites);
  };

  return (
    <tr>
      <>
        <td className='_first_row'>
          <div>
            {userFavorites?.userFavoriteSponsors?.some(
              (fav: string) => fav === sponsor.sponsor_id
            ) ? (
              <div>
                <RiHeartFill
                  className='_add_to_favorite'
                  onClick={() => handleAddFavorite(sponsor.sponsor_id)}
                />
              </div>
            ) : (
              <div>
                <RiHeartLine
                  className='_add_to_favorite'
                  onClick={() => handleAddFavorite(sponsor.sponsor_id)}
                />
              </div>
            )}
            <div
              className='_logo_name'
              onClick={() => {
                setActiveComponent(1);
                setActivePagination(1);
                setSelectedSponsor(sponsor);
                setSelectedEleInfo({ id: sponsor.sponsor_id, type: "Sponsor" });
              }}>
              <img
                src={sponsor.sponsor_logo}
                alt=''
                className='_sponsors_logos'
              />
              <li> {sponsor.sponsor_name}</li>
            </div>
          </div>
        </td>
        <td className='_dashboard_industry'>{sponsor.sponsor_industry}</td>
        <td className='_dashboard_sponsorships'>
          {sponsor.num_of_sponsored_videos}
        </td>
        <td className='_tags_row'>
          <div>
            {sponsor.sponsor_tags.map((tag, index) => (
              <li key={index} className='_sponsor_tags'>
                {tag}
              </li>
            ))}
          </div>
        </td>
        <td className='_dashboard_pIdx'>
          <div className='_popularity_idx_div'>
            {calculatePopularityIndex(sponsor)}
          </div>
        </td>
      </>
    </tr>
  );
};

export default DataTable;
