import React, { useContext } from "react";
import "./Youtubers.css";
import "../HomeBody/homeBody.css";

// Icons
import { iChannels_info } from "../../Types/creatorStuffTypes";
import { RiHeartFill, RiHeartLine } from "react-icons/ri";
import { MdOutlinePeopleAlt } from "react-icons/md";

// Global Context
import { iGlobalValues } from "../../Types/ContextGlobalVars";
import { ContextGlobalVars } from "../../Context/ContextGlobalVars";
import { iGlobalFts } from "../../Types/ContextGlobalVars";
import { ContextGlobalFts } from "../../Context/ContextGlobalFts";

// Functions
import { AddCreatorToFavorites } from "../../utils/generalUtils";

interface iCreator {
  creator: iChannels_info;
  onClick: () => void;
}

const ChannelsTable = ({ creator, onClick }: iCreator) => {
  const contextValues = useContext<Partial<iGlobalValues>>(ContextGlobalVars);
  const { userFavorites } = contextValues as iGlobalValues;

  const contextFts = useContext<Partial<iGlobalFts>>(ContextGlobalFts);
  const { formatNums, fetchUserFavorites } = contextFts as iGlobalFts;

  const handleAddFavorite = async (channel_id: string) => {
    await AddCreatorToFavorites(channel_id, fetchUserFavorites);
  };

  return (
    <tr>
      <td>
        <div className='channels_first_row_love'>
          {userFavorites?.userFavoriteCreators?.some(
            (fav: string) => fav === creator.channel_id
          ) ? (
            <RiHeartFill
              className='_add_to_favorite'
              onClick={() => handleAddFavorite(creator.channel_id)}
            />
          ) : (
            <RiHeartLine
              className='_add_to_favorite'
              onClick={() => handleAddFavorite(creator.channel_id)}
            />
          )}
          <div className='channels_first_row' onClick={onClick}>
            <img
              src={creator.channel_photo}
              alt=''
              className='_channels_logos'
            />
            <div className='channel_names'>
              <li> {creator.channel_name}</li>
              <p className='_channel_username'> {creator.channel_username}</p>
            </div>
          </div>
        </div>
      </td>
      <td className='_tags_row'>
        <p>{creator.channel_niche}</p>
      </td>
      <td>
        <div className='_channel_subs'>
          <MdOutlinePeopleAlt />
          {formatNums(Number(creator.subscribers))}
        </div>
      </td>
      <td className='_tags_row'>
        <div className='_channel_tags'>
          {creator?.channel_tags?.map((tag, i) => (
            <p key={i}>{tag}</p>
          ))}
        </div>
      </td>
      <td className='_dashboard_pIdx'>{creator.sponsored_video_count}</td>
    </tr>
  );
};

export default ChannelsTable;
