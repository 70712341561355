import React, { useEffect, useState, useRef, useContext } from "react";
import "./verify.css";
import "../App.css";

import { onVerifyEmail } from "../API/leApi";

// Components
import Navbar from "../Components/TheHeader/index";
import Footer from "../Components/Footer/index";
import Loader from "../utils/Loader";

// Imgs
import MailChecked from "../Assets/check-mail.png";
import ErrorImg from "../Assets/error-message.png";

// Contexts
import { useAuth } from "../Context/AuthProvider";
import { iGlobalValues } from "../Types/ContextGlobalVars";
import { ContextGlobalVars } from "../Context/ContextGlobalVars";

// Router dom
import { useNavigate } from "react-router-dom";

const VerifyEmail = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [verificationSuccess, setVerificationSuccess] = useState(false);

  const { dispatch } = useAuth();
  let navigate = useNavigate();

  const contextValues = useContext<Partial<iGlobalValues>>(ContextGlobalVars);
  const { setCloseNotice } = contextValues as iGlobalValues;

  // Setting a dynamic timeout (e.g., 90 seconds)
  const timeoutDuration = 5 * 1000; // 90 seconds in milliseconds
  const remainingTimeRef = useRef(timeoutDuration / 1000);

  useEffect(() => {
    (async () => {
      const token = window.location.pathname.split("/")[2];

      try {
        setIsLoaded(true);
        const res = await onVerifyEmail(token);

        if (res?.status === 202) {
          // Save the user id and username in the context
          const { user_id, customer_id, hasUserPaid } = res?.data.user;

          dispatch({
            type: "LOGIN",
            payload: {
              accessToken: res?.data?.accessToken,
              user_id: user_id,
              customer_id: customer_id,
            },
          });

          // Set verification success state
          setVerificationSuccess(true);

          const countdownInterval = setInterval(() => {
            remainingTimeRef.current -= 1;

            if (remainingTimeRef.current <= 0) {
              clearInterval(countdownInterval);
              if (customer_id && hasUserPaid) {
                navigate(`/dashboard/${customer_id}`, { replace: true });
              } else {
                setCloseNotice(true);
                navigate("/chose-plan", { replace: true });
              }
            }
          }, 700);
        }
      } catch (error: any) {
        if (error.response.status === 500) {
          setErrorMessage("Something went wrong. Please try again.");
        } else if (error.response.status === 404) {
          setErrorMessage("The token is invalid. Please try again.");
        } else {
          setErrorMessage(`Something went wrong. Please try again.`);
        }
      } finally {
        setIsLoaded(false);
      }
    })();
  }, []);

  return (
    <>
      {isLoaded ? (
        <Loader />
      ) : errorMessage ? (
        <main className='verificationPage'>
          <Navbar />
          <div className='emailVerifiedSectionErr'>
            <img src={ErrorImg} alt='mail-checked' className='checkedMail' />
            <h3 className='verifyTitleErr'>There was an Error!</h3>
            <p className='verifyMsgErr'>{errorMessage}</p>
          </div>
          <Footer />
        </main>
      ) : verificationSuccess ? (
        <main className='verificationPage'>
          <Navbar />
          <div className='emailVerifiedSection'>
            <img src={MailChecked} alt='mail-checked' className='checkedMail' />
            <h3 className='verifyTitle'>Success!</h3>
            <p className='verifyMsg'>
              Your email has been successfully verified.
            </p>
          </div>
          <Footer />
        </main>
      ) : null}
    </>
  );
};

export default VerifyEmail;
