import React, { useState, useContext } from "react";
import "./searchPlus.css";

// Types
import { iChannels_info } from "../../Types/creatorStuffTypes";
import { iSponsors } from "../../Types/generalDataTypes";

// Icons
import { RiHeartFill, RiHeartLine } from "react-icons/ri";

// Components
import Pagination from "../pagination/pagination";

// Functions
import { AddSponsorToFavorites } from "../../utils/generalUtils";

// Context
import { ContextGlobalVars } from "../../Context/ContextGlobalVars";
import { iGlobalValues } from "../../Types/ContextGlobalVars";
import { iGlobalFts } from "../../Types/ContextGlobalVars";
import { ContextGlobalFts } from "../../Context/ContextGlobalFts";

type iSearched = {
  searchPlusData: iChannels_info[] | iSponsors[];
  activePagination: number;
};

const SearchedSponsorsTable = ({
  searchPlusData,
  activePagination,
}: iSearched) => {
  const [currentPage, setCurrentPage] = useState(activePagination);
  const [postPerPage] = useState(5);

  const indexofLastPage = currentPage * postPerPage;
  const indexOfFirstPage = indexofLastPage - postPerPage;
  const currentPages = searchPlusData?.slice(indexOfFirstPage, indexofLastPage);
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const contextValues = useContext<Partial<iGlobalValues>>(ContextGlobalVars);
  const {
    userFavorites,
    setSelectedSponsor,
    setActivePagination,
    setSelectedEleInfo,
    setActiveComponent,
  } = contextValues as iGlobalValues;

  const contextFts = useContext<Partial<iGlobalFts>>(ContextGlobalFts);
  const { fetchUserFavorites } = contextFts as iGlobalFts;

  const handleAddFavorite = async (sponsor_id: string) => {
    console.log("channel id: ", sponsor_id);
    await AddSponsorToFavorites(sponsor_id, fetchUserFavorites);
  };

  return (
    <div className='_search_plus_sponsors_container'>
      <table>
        <thead className='_table_head'>
          <tr>
            <th id='_sponsor_name'>Sponsor</th>
            <th>Industry</th>
            <th>Sponsorships</th>
            <th>Tags</th>
          </tr>
        </thead>
        <tbody className='_table_body'>
          {currentPages?.map((sponsor: iChannels_info | iSponsors) =>
            (sponsor as iSponsors).sponsor_id ? (
              <tr>
                <td className='_first_row'>
                  <div>
                    {userFavorites?.userFavoriteSponsors?.some(
                      (fav: string) => fav === (sponsor as iSponsors).sponsor_id
                    ) ? (
                      <div>
                        <RiHeartFill
                          className='_add_to_favorite'
                          onClick={() =>
                            handleAddFavorite((sponsor as iSponsors).sponsor_id)
                          }
                        />
                      </div>
                    ) : (
                      <div>
                        <RiHeartLine
                          className='_add_to_favorite'
                          onClick={() =>
                            handleAddFavorite((sponsor as iSponsors).sponsor_id)
                          }
                        />
                      </div>
                    )}
                    <div
                      className='_logo_name'
                      onClick={() => {
                        setSelectedSponsor(sponsor as iSponsors);
                        setSelectedEleInfo({
                          id: (sponsor as iSponsors).sponsor_id,
                          type: "Sponsor",
                        });
                        setActiveComponent(1);
                        setActivePagination(1);
                      }}>
                      <img
                        src={(sponsor as iSponsors).sponsor_logo}
                        alt=''
                        className='_sponsors_logos'
                      />
                      <li> {(sponsor as iSponsors).sponsor_name}</li>
                    </div>
                  </div>
                </td>
                <td className='_dashboard_industry'>
                  {(sponsor as iSponsors).sponsor_industry}
                </td>
                <td className='_dashboard_sponsorships'>
                  {(sponsor as iSponsors).num_of_sponsored_videos}
                </td>
                <td className='_tags_row'>
                  <div>
                    {(sponsor as iSponsors).sponsor_tags.map((tag, index) => (
                      <li key={index} className='_sponsor_tags'>
                        {tag}
                      </li>
                    ))}
                  </div>
                </td>
              </tr>
            ) : null
          )}
        </tbody>
      </table>

      <Pagination
        postPerPage={postPerPage}
        totalPages={searchPlusData?.length}
        paginate={paginate}
        currentPage={currentPage}
        setActivePagination={setActivePagination}
      />
    </div>
  );
};

export default SearchedSponsorsTable;
