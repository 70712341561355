import React, { useState, useRef } from "react";
import "./Features.css";

import { useNavigate } from "react-router-dom";

import { useAuth } from "../../Context/AuthProvider";

const Index = () => {
  const [selectedFeature, setSelectedFeature] = useState(0);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const { state } = useAuth();
  let navigate = useNavigate();

  const handleAccess = () => {
    state.hasUserPaid && state.customer_id
      ? navigate(`/dashboard/${state.customer_id}`)
      : navigate("/signUp");
  };

  const features = [
    {
      title: "Analyze Your Competitors First",
      description: `See which YouTubers and creators are partnering with brands in your niche.\n\nGet a clear view of potential sponsorships and use competitor insights to craft a strategy that connects you with the right brands.`,
      videoSrc: "/path/to/video2.mp4",
    },
    {
      title: "Check out Their Brand Collaborations.",
      description:
        "Easily find sponsors in your niche that align with your content  and audience preferences by tracking sponsor's collaborations with YouTubers.",
      videoSrc: "/path/to/video3.mp4",
    },
    {
      title: "Evaluate a Potential Sponsor's Fit.",
      description:
        "By selecting a sponsor, you can find comprehensive information about their collaborations and the YouTubers they work with. Add it to your favorites for easy reference & start pitching.",
      videoSrc: "/path/to/video1.mp4",
    },
  ];

  // Function to render description with line breaks
  const renderDescription = (description: string) => {
    return description.split("\n").map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  };

  const handleFeatureClick = (index: number) => {
    setSelectedFeature(index);
    // setProgress(0); // Reset progress when a new feature is selected
  };

  // const handleTimeUpdate = () => {
  //   if (videoRef.current) {
  //     const currentTime = videoRef?.current?.currentTime as number;
  //     const duration = videoRef.current.duration as number;
  //     setProgress((currentTime / duration) * 100);
  //   }
  // };

  return (
    <main className='mainGrid'>
      <div className='_features_title'>
        <h3>YouTube Creators...</h3>
        <p>
          No more sitting back and waiting for sponsors to reach out to you.
          Sponsorwave lets you discover sponsors that align with your content,
          boost your earnings, and focus on what you love doing—creating great
          content.
        </p>
      </div>

      {/* <div className='_features_div'> */}
      <div className='_features_container'>
        {features.map((feature, index) => (
          <div className='_feature_container'>
            <div
              onClick={() => handleFeatureClick(index)}
              key={index}
              className={`_feature_text _d_${index} ${
                index === selectedFeature ? "selected" : ""
              }`}>
              <h3>{feature.title}</h3>
              <p>{renderDescription(feature.description)}</p>
              {/* <div className='progress-bar'>
                <div
                  className='progress-fill'
                  style={{ width: `${progress}%` }}></div>
              </div> */}
            </div>

            {index === selectedFeature && (
              <div className='_middle_img_container'>
                <video
                  ref={videoRef}
                  src={features[selectedFeature].videoSrc}
                  className='_feature_video'
                  controls
                  // onTimeUpdate={handleTimeUpdate}
                  autoPlay
                />
              </div>
            )}
          </div>
        ))}
      </div>
      {/* </div> */}

      {/* <button className='_features_btn' onClick={() => handleAccess()}>
        Get Started Today
      </button> */}

      <button className='_features_btn' onClick={() => navigate("/waitlist")}>
        Join Waitlist
      </button>
    </main>
  );
};

export default Index;
