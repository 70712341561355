import React, { useState, createContext, useEffect } from "react";

// API
import { onGetSponsors, onGetSponsor, onGetChannel } from "../API/leApi";

// Types
import { iSponsors, iSelectedInfo } from "../Types/generalDataTypes";
import { iChannels_info, iUserProfile } from "../Types/creatorStuffTypes";

// Global Context
import { iGlobalValues } from "../Types/ContextGlobalVars";
import { useAuth } from "./AuthProvider";

// Local Storage for persistent
const savedPage: number =
  JSON.parse(localStorage.getItem("ActivePage") as string) || 0;
let savedPaginationPosition =
  JSON.parse(localStorage.getItem("PaginationPosition") as string) || 1;
let pagePosition: iSelectedInfo = (() => {
  const storedData = localStorage.getItem("PagePosition");
  return storedData
    ? (JSON.parse(storedData) as iSelectedInfo)
    : ({} as iSelectedInfo);
})();

const ContextGlobalVars = createContext<iGlobalValues | {}>({});
const GlobalValuesProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [reverificationSuccess, setReverificationSuccess] = useState("");
  const [serverErrMsg, setServerErrMsg] = useState("");
  const [closeNotice, setCloseNotice] = useState(false);

  const [refetchCreatorData, setRefetchCreatorData] = useState(false);
  const [sponsors, setSponsors] = useState<iSponsors[] | undefined>([]);
  const [getUserData, setGetUserData] = useState<iUserProfile[] | undefined>(
    []
  );
  const [YouTubers, setYouTubers] = useState<iChannels_info[] | null>([]);
  const [userFavorites, setUserFavorites] = useState({
    userFavoriteSponsors: [] as string[] | [],
    userFavoriteCreators: [] as string[] | [],
    user_id: "",
  });

  const [activeComponent, setActiveComponent] = useState(savedPage); // Track the active component page index
  const [activePagination, setActivePagination] = useState(
    savedPaginationPosition
  ); // Track the active pagination position
  const [selectedEleInfo, setSelectedEleInfo] =
    useState<iSelectedInfo>(pagePosition);

  let [selectedSponsor, setSelectedSponsor] = useState<iSponsors | null>();
  const [selectedChannel, setSelectedChannel] =
    useState<iChannels_info | null>();

  let { state } = useAuth();
  useEffect(() => {
    (async () => {
      try {
        let res = await onGetSponsors();

        if (res.status === 200) {
          setSponsors(res.data.sponsors);
          setYouTubers(res.data?.youtube_channels);
          setGetUserData(res.data?.user_data);
        }
      } catch (err: any) {
        if (state.accessToken && state.customer_id && state.hasUserPaid)
          alert(
            `There was an error getting the requested info, try to refresh it again`
          );
      }
    })();
  }, [state.customer_id]);

  useEffect(() => {
    (async () => {
      try {
        if (selectedEleInfo.type === "Sponsor" && selectedEleInfo.id) {
          let res = await onGetSponsor(selectedEleInfo.id);

          if (res.status === 200) {
            setSelectedSponsor(res.data.la_sponsor);
          }
        }

        if (selectedEleInfo.type === "Channel" && selectedEleInfo.id) {
          let res = await onGetChannel(selectedEleInfo.id);

          if (res.status === 200) {
            setSelectedChannel(res.data.la_channel);
          }
        }
      } catch (error) {
        alert(`Something is not right, please refresh the page!`);
      }
    })();
  }, []);

  useEffect(() => {
    localStorage.setItem("ActivePage", JSON.stringify(activeComponent));
    localStorage.setItem(
      "PaginationPosition",
      JSON.stringify(activePagination)
    );
    localStorage.setItem("PagePosition", JSON.stringify(selectedEleInfo));
  }, [activeComponent, activePagination, selectedEleInfo]);

  return (
    <ContextGlobalVars.Provider
      value={{
        reverificationSuccess,
        setReverificationSuccess,
        serverErrMsg,
        setServerErrMsg,
        refetchCreatorData,
        setRefetchCreatorData,
        closeNotice,
        setCloseNotice,
        sponsors,
        YouTubers,
        setYouTubers,
        activeComponent,
        setActiveComponent,
        selectedEleInfo,
        setSelectedEleInfo,
        activePagination,
        setActivePagination,
        selectedSponsor,
        setSelectedSponsor,
        selectedChannel,
        setSelectedChannel,
        userFavorites,
        setUserFavorites,
        getUserData,
      }}>
      {children}
    </ContextGlobalVars.Provider>
  );
};

export { GlobalValuesProvider, ContextGlobalVars };
