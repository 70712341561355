import React, { useEffect, useState } from "react";
import "./PagesStyle.css";
import Home from "../Container/Home/index";

const Dashboard = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (isMobile) {
    return (
      <div style={{ textAlign: "center", marginTop: "20%", padding: "20px" }}>
        <h2 className='_unavailability_message'>
          Sorry, the dashboard is not available on small devices.
        </h2>
        <p className='_unavailability_message_notice'>
          Please visit us on a desktop...
        </p>
      </div>
    );
  }

  return (
    <>
      <Home />
    </>
  );
};

export default Dashboard;
