import React from "react";
import "./footer.css";

import FooterLogo from "../../Assets/_sponsorwave.png";
import { useNavigate } from "react-router-dom";

import { RiTwitterXLine } from "react-icons/ri";
import { GrLinkedinOption } from "react-icons/gr";

const Index = () => {
  const laDate = new Date().getFullYear();
  let navigate = useNavigate();

  return (
    <footer className='Footer'>
      <main className='footerMain'>
        <div className='logoAndSM'>
          <img
            src={FooterLogo}
            alt='footer_logo'
            className='_footer_logo'
            onClick={() => navigate("/")}
          />
          <div>
            <p className='sabli'>by Sabli Jr.</p>
            <div className='SocialIcons'>
              <a
                href='https://twitter.com/sablijr'
                target='_blank'
                rel='noopener noreferrer'>
                <RiTwitterXLine />
              </a>
              <a
                href='https://www.linkedin.com/in/sablijr/'
                target='_blank'
                rel='noopener noreferrer'>
                <GrLinkedinOption />
              </a>
            </div>
          </div>
        </div>
        <div className='footerLinks'>
          <div>
            <h3>Legal</h3>
            <p onClick={() => navigate("/terms-of-service")}>
              Terms of Service
            </p>
            <p onClick={() => navigate("/privacy-policy")}> Privacy Policy</p>
          </div>
          <div>
            <h3>General</h3>
            <p onClick={() => navigate("/contact_us")}>Contact</p>
          </div>
        </div>
      </main>
      <div className='copy'>
        <p>Sponsorwave {laDate}</p>
      </div>
    </footer>
  );
};

export default Index;
