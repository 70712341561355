import React, { useState, useEffect } from "react";
import "./Header.css";

import { Link, useNavigate, useLocation } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

import Logo from "../../Assets/_sponsorwave.png";
import { RiMenu4Line } from "react-icons/ri";

import { useAuth } from "../../Context/AuthProvider";
import useRefreshToken from "../../Hooks/useRefreshToken";

const Index = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleTrigger = () => setIsOpen(!isOpen);

  const { refresh } = useRefreshToken();
  let { state } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  useEffect(() => {
    refresh();
  }, [location.pathname]);

  return (
    <header className='Header'>
      <Link to='/'>
        <div className='_header_logo_div'>
          <img src={Logo} alt='logo' className='_sponsor_wave' />
          {/* <p className='_beta'>Beta</p> */}
        </div>
      </Link>

      <div className='_cart_menu_div'>
        <div className={`navStuff ${isOpen ? "navStaff expand" : ""}`}>
          <div className='navButtons'>
            <nav className='_nav'>
              {isHomePage && (
                <>
                  <ScrollLink
                    to='pricing_scroll'
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}>
                    <li className='_faq'>Pricing</li>
                  </ScrollLink>
                  <ScrollLink
                    to='faq'
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}>
                    <li className='_faq'>FAQ</li>
                  </ScrollLink>
                </>
              )}
              {state.customer_id && state.hasUserPaid ? null : (
                <li>
                  <Link to='/login' id='_login_text' className='_faq'>
                    Login
                  </Link>
                </li>
              )}
              {state.customer_id && state.hasUserPaid ? (
                <button
                  className='_dashboard_btn'
                  onClick={() => navigate(`/dashboard/${state.customer_id} `)}>
                  Dashboard
                </button>
              ) : (
                <button onClick={() => navigate("/signUp")}>SignUp</button>
              )}
            </nav>
          </div>
        </div>
        <RiMenu4Line className='menuIcon' onClick={handleTrigger} />
      </div>
    </header>
  );
};

export default Index;
