import React from "react";
import "./pagination.css";

interface iPagination {
  postPerPage: number;
  totalPages: number | undefined;
  paginate: (pageNumber: number) => void;
  currentPage: number;
  setActivePagination: React.Dispatch<React.SetStateAction<number>>;
}

const Pagination = ({
  postPerPage,
  totalPages,
  paginate,
  currentPage,
  setActivePagination,
}: iPagination) => {
  if (!totalPages) return null;

  const pageNumbers = [];
  const maxPagesToShow = 5;
  const totalPageCount = Math.ceil((totalPages as number) / postPerPage);

  let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
  let endPage = Math.min(totalPageCount, startPage + maxPagesToShow - 1);

  if (endPage - startPage < maxPagesToShow - 1) {
    startPage = Math.max(1, endPage - maxPagesToShow + 1);
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <ul className='pagination'>
      {/* Previous Arrow */}
      {currentPage > 1 && (
        <li
          className='PaginateNum'
          onClick={() => {
            paginate(currentPage - 1);
            setActivePagination(currentPage - 1);
          }}>
          {"<"}
        </li>
      )}

      {/* First Page and Dots */}
      {startPage > 1 && (
        <>
          <li
            className='PaginateNum'
            onClick={() => {
              paginate(1);
              setActivePagination(1);
            }}>
            1
          </li>
          {startPage > 2 && <li className='dots'>...</li>}
        </>
      )}

      {/* Page Numbers */}
      {pageNumbers.map((number) => (
        <li
          key={number}
          onClick={() => {
            paginate(number);
            setActivePagination(number);
          }}
          className={
            number === currentPage ? "active PaginateNum" : "PaginateNum"
          }>
          {number}
        </li>
      ))}

      {/* Last Page and Dots */}
      {endPage < totalPageCount && (
        <>
          {endPage < totalPageCount - 1 && <li className='dots'>...</li>}
          <li
            className='PaginateNum'
            onClick={() => {
              paginate(totalPageCount);
              setActivePagination(totalPageCount);
            }}>
            {totalPageCount}
          </li>
        </>
      )}

      {/* Next Arrow */}
      {currentPage < totalPageCount && (
        <li
          className='PaginateNum'
          onClick={() => {
            paginate(currentPage + 1);
            setActivePagination(currentPage + 1);
          }}>
          {">"}
        </li>
      )}
    </ul>
  );
};

export default Pagination;
