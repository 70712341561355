import React, { useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { onSuccessCheckingOut } from "../../API/leApi";
import { useAuth } from "../../Context/AuthProvider";
import { ContextGlobalVars } from "../../Context/ContextGlobalVars";
import { iGlobalValues } from "../../Types/ContextGlobalVars";

const SubscriptionSuccess = () => {
  const contextValues = useContext<Partial<iGlobalValues>>(ContextGlobalVars);
  const { setCloseNotice } = contextValues as iGlobalValues;
  const location = useLocation();
  const navigate = useNavigate();
  const sessionId = new URLSearchParams(location.search).get("session_id");
  const { setRegisterValues } = useAuth();

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const res = await onSuccessCheckingOut(sessionId as string);
        if (res.status === 200) {
          let { customer_id } = res.data.user;

          navigate(`/dashboard/${customer_id}`, { replace: true });
        } else if (res.status === 201) {
          let customer_email = res.data.user_info.customer_email;
          setCloseNotice(true);
          setRegisterValues((prev) => ({
            ...prev,
            email: customer_email,
          }));
          navigate("/signUp", { replace: true });
        }
      } catch (error) {
        alert(
          "The payment didn't go through, something went wrong please try again!"
        );
      }
    };

    fetchSession();
  }, [sessionId]);

  return <></>;
};

export default SubscriptionSuccess;
