import React from "react";
import Skeleton from "../utils/skeleton";

import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
  let navigate = useNavigate();

  return (
    <Skeleton>
      <div className='_privacy_container'>
        <div className='_policy_title'>
          <h1>Sponsorwave Privacy Policy.</h1>
          <p>Last Updated: 23.10.2024</p>
        </div>
        <span>
          <p>
            Sponsorwave is committed to protecting your privacy and personal
            data. This Privacy Policy outlines the data we collect, use, share,
            and protect when you use our services. Please read this policy
            carefully to understand our practices.
          </p>
        </span>
        <div className='_policy_points_container'>
          <div>
            <h3>1. Personal Data Collection:</h3>
            <p>
              Sponsorwave collects personal information necessary for account
              creation, secure service provision, and quality improvement. This
              data includes your name, email address, payment information, IP
              address, and activity data.
              <br />
              <br />
              Additionally, we may collect social media information, such as
              your YouTube channel, to tailor our services and enhance your
              experience on the platform.
            </p>
          </div>
          <div>
            <h3>2. Purpose of Data Collection:</h3>
            <p>
              Sponsorwave collects and processes personal information primarily
              to provide and improve our services, facilitate account creation,
              ensure secure access, enhance the platform’s functionality, and
              comply with any applicable legal obligations.
              <br />
              <br />
              Data such as your email and name is collected to authenticate your
              account, while other data helps us analyze usage patterns,
              optimize our services, securely manage payments, and prevent fraud
              or misuse of the platform.
            </p>
          </div>
          <div>
            <h3>3. Data Sharing and Third Parties:</h3>
            <p>
              Sponsorwave may share your data with trusted third-party service
              providers only to the extent necessary for operational support and
              service quality. Data shared with third parties is used solely to
              provide or improve our services and is handled securely in
              compliance with applicable laws.
              <br />
              <br />
              We take necessary precautions to ensure that any third-party
              processing of data maintains the confidentiality and security of
              user information.
            </p>
          </div>
          <div>
            <h3>4. Data Storage and Security:</h3>
            <p>
              We employ industry-standard security measures to store and protect
              user data from unauthorized access and disclosure. Data is stored
              securely within the jurisdiction of our operations and may be
              encrypted where applicable to prevent unauthorized access. We
              prioritize the safeguarding of personal information through these
              industry-standard practices.
            </p>
          </div>
          <div>
            <h3>5. Data Retention:</h3>
            <p>
              Sponsorwave retains personal data as long as necessary to fulfill
              the purposes outlined in this policy, typically up to one year
              following account termination. This retention period supports
              recordkeeping, audit requirements, and ongoing service
              improvement. We also comply with government and legal requests for
              data access as required by applicable laws, regulations, or court
              orders.
            </p>
          </div>
          <div>
            <h3>6. User Rights and Obligations:</h3>
            <p>
              By using Sponsorwave, users agree to comply with applicable laws
              and our{" "}
              <span onClick={() => navigate("/terms-of-service")}>
                Terms of Service
              </span>
              . Sponsorwave retains all rights to its intellectual property,
              including but not limited to data, logos, graphical assets, text,
              images, and software.
              <br />
              <br />
              Users are prohibited from redistributing, sharing, or otherwise
              infringing upon Sponsorwave’s proprietary content. Unauthorized
              use, distribution, or modification of Sponsorwave’s intellectual
              property may result in account termination and potential legal
              action.
              <br />
              <br />
              Additionally, users are prohibited from engaging in unlawful
              activities, violating intellectual property rights, or performing
              malicious activities on the platform. Sponsorwave reserves the
              right to terminate any account at its sole discretion if deemed
              necessary for compliance, legal, or operational reasons.
            </p>
          </div>
          <div>
            <h3>7. Cookies and Tracking:</h3>
            <p>
              Sponsorwave uses essential cookies necessary for platform
              functionality and analytical cookies to monitor user interactions
              and improve service quality. These cookies track user activity on
              our platform, enabling us to analyze and enhance our offerings.
              Users consent to the use of these tracking methods upon accessing
              the website.
            </p>
          </div>
          <div>
            <h3>8. Children's Privacy:</h3>
            <p>
              Sponsorwave’s platform is not intended for individuals under the
              age of 18. We do not knowingly collect personal data from minors.
              Although Sponsorwave does not contain harmful content, it is
              recommended that minors obtain parental consent before using the
              platform. We reserve the right to restrict access if needed, and
              any suspected collection of data from minors will be deleted
              promptly.
            </p>
          </div>
          <div>
            <h3>9. Policy Updates:</h3>
            <p>
              Sponsorwave reserves the right to modify this Privacy Policy to
              reflect changes in our services, legal requirements, or
              operational needs. While we will make reasonable efforts to inform
              users of significant updates, we recommend that users review the
              Privacy Policy periodically to remain informed of any changes.
            </p>
          </div>
          <div>
            <p>
              For any questions or concerns regarding this Privacy Policy or
              Sponsorwave’s practices, please feel free to contact us at{" "}
              <span>info.sponsorwave@gmail.com</span>.
            </p>
          </div>
        </div>
      </div>
    </Skeleton>
  );
};

export default PrivacyPolicy;
