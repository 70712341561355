import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./Pages/Home";
import Login from "./Components/signup/Login";
import SingUp from "./Components/signup/SignUp";
import ContactUs from "./Pages/Contact/contactUs";
import StoreChannel from "./Pages/storeChannel";
import SubscriptionSuccess from "./Components/Pricing/paymentSuccess";
import VerifyEmail from "./Verifications/verifyEmail";
import VerificationPage from "./Pages/verifyPage";
import ChosePlan from "./Pages/chosePlan";
import CheckEmail from "./Pages/checkMail";
import NotFound from "./Pages/404";
import ProtectedRoute from "./utils/ProtectedRoutes";
import AccountSettings from "./Pages/AccountSettings";
import Dashboard from "./Pages/dashboard";
import StoreChannelException from "./Pages/storeChannelException";
import DeleteChannel from "./Pages/deleteChannel";
import WaitList from "./Pages/waitlist";
import PrivacyPolicy from "./Pages/privacyPolicy";
import Tos from "./Pages/tos";

const RoutesFile = () => {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/login' element={<Login />} />
        <Route path='/signUp' element={<SingUp />} />
        <Route path='/contact_us' element={<ContactUs />} />
        <Route path='/store_channel' element={<StoreChannel />} />
        <Route path='/delete_channel' element={<DeleteChannel />} />
        <Route path='/success' element={<SubscriptionSuccess />} />
        <Route path='/verify' element={<VerificationPage />} />
        <Route path='/verify-email/:token' element={<VerifyEmail />} />
        <Route path='/chose-plan' element={<ChosePlan />} />
        <Route path='/check-email' element={<CheckEmail />} />
        <Route path='/waitlist' element={<WaitList />} />
        <Route path='/terms-of-service' element={<Tos />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/*' element={<NotFound />} />
        <Route
          path='/store_channel_exception'
          element={<StoreChannelException />}
        />

        <Route element={<ProtectedRoute />}>
          <Route path='/account-settings' element={<AccountSettings />} />
          <Route path='/dashboard/:customer_id' element={<Dashboard />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default RoutesFile;
