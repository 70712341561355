import React, { useState, useEffect, useContext } from "react";
import "./homeBody.css";

// The icons
import { CgArrowDown, CgArrowUp } from "react-icons/cg";

// The components
import LaTable from "../Table/LaTable";

// The api
import { onGetHomeStats, onGetGrowthMetrics } from "../../API/leApi";

// The youTubers Images & other assets
import Sponsors from "../../Assets/sponsors.png";
import Creators from "../../Assets/youtuber1.png";
import SponsoredVideos from "../../Assets/sponsor.png";
import Ali from "../../Assets/Ali.jpg";
import Ruri from "../../Assets/ruri.jpg";
import Dave2d from "../../Assets/dev2d.jpg";
import Unnamed from "../../Assets/unnamed.jpg";
import Sara from "../../Assets/sara.jpg";

// Global contexts
import { iGlobalFts } from "../../Types/ContextGlobalVars";
import { ContextGlobalFts } from "../../Context/ContextGlobalFts";
import { iGlobalValues } from "../../Types/ContextGlobalVars";
import { ContextGlobalVars } from "../../Context/ContextGlobalVars";

const Index = () => {
  const [getStats, setGetStats] = useState({
    sponsors: 0,
    sponsored_videos: 0,
    YouTubers: 0,
  });
  const [growthData, setGrowthData] = useState({
    youTubersGrowth: 0,
    sponsoredVideosGrowth: 0,
    sponsorsGrowth: 0,
  });

  const contextFts = useContext<Partial<iGlobalFts>>(ContextGlobalFts);
  const { formatNums } = contextFts as iGlobalFts;
  const contextValues = useContext<Partial<iGlobalValues>>(ContextGlobalVars);
  const { activePagination } = contextValues as iGlobalValues;

  const onGetGrowthData = async () => {
    try {
      let res = await onGetGrowthMetrics();
      console.log("The growth res: ", res);

      if (res.status === 200) {
        let { youTubersGrowth, sponsorsGrowth, sponsoredVideosGrowth } =
          res.data.growth_data;

        setGrowthData({
          sponsorsGrowth: sponsorsGrowth,
          sponsoredVideosGrowth: sponsoredVideosGrowth,
          youTubersGrowth: youTubersGrowth,
        });
      }
    } catch (error: any) {
      alert(`Something went wrong, please refresh the page again!`);
    }
  };

  const handelHomeStats = async () => {
    try {
      const res = await onGetHomeStats();
      console.log(res);

      if (res.status === 200) {
        setGetStats(res.data);
      }
    } catch (error) {
      alert("Something went wrong in getting dashboard data, try again!");
    }
  };

  useEffect(() => {
    onGetGrowthData();
    handelHomeStats();
  }, []);

  return (
    <>
      <div className='_hb_header'>
        <div className='_hb_ele_container'>
          <img src={Sponsors} alt='sponsors_icon' className='_hb_icon' />
          <div className='_text_holder'>
            <p className='_stats_title'>Sponsors</p>
            <h4 className='_stats_numbers'>{formatNums(getStats?.sponsors)}</h4>
            <div className='_stats_holder'>
              {Number(growthData?.sponsorsGrowth) > 0 ? (
                <CgArrowUp className='_dataUp' />
              ) : (
                <CgArrowDown className='_dataDown' />
              )}
              <p className='_this_month'>
                <span
                  className={
                    Number(growthData?.sponsorsGrowth) > 0
                      ? "_dataUp"
                      : "_dataDown"
                  }>
                  {Number(growthData?.sponsorsGrowth)}%
                </span>{" "}
                This month
              </p>
            </div>
          </div>
        </div>
        <div className='_hb_ele_container'>
          <img src={SponsoredVideos} alt='' className='_hb_icon' />
          <div className='_text_holder'>
            <p className='_stats_title'>Sponsored Videos</p>
            <h4 className='_stats_numbers'>
              {formatNums(getStats?.sponsored_videos)}
            </h4>
            <div className='_stats_holder'>
              {Number(growthData?.sponsoredVideosGrowth) > 0 ? (
                <CgArrowUp className='_dataUp' />
              ) : (
                <CgArrowDown className='_dataDown' />
              )}
              <p className='_this_month'>
                <span
                  className={
                    Number(growthData?.sponsoredVideosGrowth) > 0
                      ? "_dataUp"
                      : "_dataDown"
                  }>
                  {Number(growthData?.sponsoredVideosGrowth)}%{" "}
                </span>
                This month
              </p>
            </div>
          </div>
        </div>
        <div className='_hb_ele_container'>
          <img
            src={Creators}
            alt=''
            className='_hb_icon'
            id='_hb_creators_icon'
          />
          <div className='_text_holder'>
            <div className='_text_holder'>
              <p className='_stats_title'>YouTubers</p>
              <h4 className='_stats_numbers'>
                {formatNums(getStats?.YouTubers)}
              </h4>
              <p className='_this_month'>
                <span
                  className={
                    Number(growthData?.youTubersGrowth) > 0
                      ? "_dataUp"
                      : "_dataDown"
                  }>
                  {Number(growthData?.youTubersGrowth)}%{" "}
                </span>
                This month
              </p>
            </div>
            <div className='_stats_holder'>
              <img src={Ali} alt='' className='_dashboard_creator_img' />
              <img src={Sara} alt='' className='_dashboard_creator_img' />
              <img src={Dave2d} alt='' className='_dashboard_creator_img' />
              <img src={Ruri} alt='' className='_dashboard_creator_img' />
              <img src={Unnamed} alt='' className='_dashboard_creator_img' />
            </div>
          </div>
        </div>
      </div>
      <LaTable howMany={5} no={98} activePagination={activePagination} />
    </>
  );
};

export default Index;
