import React from "react";
import "../App.css";

import Hero from "../Components/Hero/index";
import Features from "../Components/Features/index";
import Header from "../Components/TheHeader/index";
import Footer from "../Components/Footer/index";
// import Middle from "../Components/middle/index";
import Pricing from "../Components/Pricing/index";
import FAQ from "../Components/FAQ/index";
// import HowItWorks from "../Components/HowPlatformWorks/index";

const Home = () => {
  return (
    <>
      <div className='Home'>
        <Header />
        <Hero />
        <Features />
        {/* <Middle /> */}
        {/* <HowItWorks /> */}
        <Pricing />
        <FAQ />
        <Footer />
      </div>
    </>
  );
};

export default Home;
