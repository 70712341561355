import React from "react";

import "./Hero.css";

import Marquee from "react-fast-marquee";

import img1 from "../../Assets/SponsorsLogos/Audiable.png";
import img2 from "../../Assets/SponsorsLogos/Bett.png";
import img3 from "../../Assets/SponsorsLogos/Bri.png";
import img4 from "../../Assets/SponsorsLogos/Fiverr.png";
import img5 from "../../Assets/SponsorsLogos/_honey.png";
import img6 from "../../Assets/SponsorsLogos/Hos.png";
import img7 from "../../Assets/SponsorsLogos/Lingoda.png";
import img8 from "../../Assets/SponsorsLogos/Nord.png";
import img9 from "../../Assets/SponsorsLogos/R.png";
import img10 from "../../Assets/SponsorsLogos/River.png";
import img11 from "../../Assets/SponsorsLogos/SKillShare.png";
import img12 from "../../Assets/SponsorsLogos/SS.png";
import img13 from "../../Assets/SponsorsLogos/US.png";
import img14 from "../../Assets/SponsorsLogos/Wix.png";
import img15 from "../../Assets/SponsorsLogos/es.png";
import HubSpot from "../../Assets/SponsorsLogos/HubSpot-Logo-PNG.png";
import Incogni from "../../Assets/SponsorsLogos/Incogni.png";
import Babble from "../../Assets/SponsorsLogos/Babbel.png";
import AG1 from "../../Assets/SponsorsLogos/AG1_TM_Logo_Green1.png";
import Upside from "../../Assets/SponsorsLogos/Upside-Logo.png";

import { useAuth } from "../../Context/AuthProvider";
import { useNavigate } from "react-router-dom";

import La_platform from "../../Assets/HomePage.png";

const Index = () => {
  const { state } = useAuth();
  let navigate = useNavigate();

  const handleAccess = () => {
    state.hasUserPaid && state.customer_id
      ? navigate(`/dashboard/${state.customer_id}`)
      : navigate("/signUp");
  };

  return (
    <>
      <main className='hero'>
        <div className='_hero_text_container'>
          <h2>
            Find Sponsors That Fit Your Content<span>, Instantly</span>.
          </h2>
          <p>
            Discover companies already sponsoring creators on YouTube and pitch
            those that align with your audience, helping you fully monetize your
            content.
          </p>

          {/* <button className='_get_started_hero' onClick={() => handleAccess()}>
            Get Started
          </button> */}

          <button
            className='_get_started_hero'
            onClick={() => navigate("/waitlist")}>
            Join waitlist
          </button>
        </div>

        <img src={La_platform} alt='' className='_hero_img' />
      </main>

      {/* <p>
            Find sponsors that align with your content, increase your earnings,
            and focus on what you love doing—creating great content.
          </p> */}
      {/* <p>
          Helping <span>Creators</span> Make a Living Doing what they love while
          enabling <span>companies</span> to reach their potential customers!
          </p> */}

      {/* <div className='_hero_img_container'> */}
      {/* </div> */}
      {/* <img src={Lines} alt='' className='_bottom_lines' /> */}

      <section className='_sample_logos_section'>
        <h3 className='_brands_logs'>Sample Of Brands:</h3>
        <div className='_the_logos_div'>
          <Marquee direction='right' speed={100} delay={5}>
            <div className='image_wrapper'>
              <img src={img1} alt='' />
            </div>
            <div className='image_wrapper'>
              <img src={img2} alt='' />
            </div>
            <div className='image_wrapper'>
              <img src={img3} alt='' />
            </div>
            <div className='image_wrapper'>
              <img src={img4} alt='' />
            </div>
            <div className='image_wrapper'>
              <img src={img5} alt='' />
            </div>
            <div className='image_wrapper'>
              <img src={img6} alt='' />
            </div>
            <div className='_extra_size'>
              <img src={img7} alt='' />
            </div>
            <div className='image_wrapper'>
              <img src={img8} alt='' />
            </div>
            <div className='image_wrapper'>
              <img src={img9} alt='' />
            </div>
            <div className='_extra_size'>
              <img src={img10} alt='' />
            </div>
            <div className='image_wrapper'>
              <img src={img11} alt='' />
            </div>
            <div className='image_wrapper'>
              <img src={img12} alt='' />
            </div>
            <div className='_extra_size'>
              <img src={img13} alt='' />
            </div>
            <div className='image_wrapper'>
              <img src={img14} alt='' />
            </div>
            <div className='image_wrapper'>
              <img src={img15} alt='' />
            </div>
            <div className='image_wrapper'>
              <img src={HubSpot} alt='' />
            </div>
            <div className='image_wrapper'>
              <img src={Incogni} alt='' />
            </div>
            <div className='_extra_size'>
              <img src={Babble} alt='' />
            </div>
            <div className='image_wrapper'>
              <img src={AG1} alt='' />
            </div>
            <div className='_extra_size'>
              <img src={Upside} alt='' />
            </div>
          </Marquee>
        </div>
      </section>
    </>
  );
};

export default Index;
