import React from "react";
import Skeleton from "../utils/skeleton";

import { useNavigate } from "react-router-dom";

const Tos = () => {
  let navigate = useNavigate();

  return (
    <Skeleton>
      <div className='_privacy_container'>
        <div className='_policy_title'>
          <h1>Terms of Service.</h1>
          <p>Last Updated: 23.10.2024</p>
        </div>
        <span>
          <p>
            Welcome to Sponsorwave! By using our platform, you agree to these
            Terms of Service. Please read them carefully before accessing or
            using our services.
          </p>
        </span>
        <div className='_policy_points_container'>
          <div>
            <h3> 1. Acceptance of Terms:</h3>
            <p>
              By creating an account or otherwise using Sponsorwave, you agree
              to be bound by these Terms of Service (“Terms”). If you do not
              agree to these Terms, you may not use the platform.
            </p>
          </div>
          <div>
            <h3>2. Eligibility and Age Restriction:</h3>
            <p>
              Sponsorwave is intended for individuals aged 18 and over. By using
              the platform, you confirm that you are at least 18 years old or
              have the consent and supervision of a parent or guardian.
              <br />
              <br />
              Sponsorwave does not knowingly collect or process data from
              individuals under 18 without parental consent. Accounts found in
              violation of this policy may be terminated without notice.
            </p>
          </div>
          <div>
            <h3>3. User Account and Security:</h3>
            <p>
              You are responsible for maintaining the confidentiality of your
              account credentials and for all activities that occur under your
              account. Sponsorwave reserves the right to suspend or terminate
              your account if it suspects unauthorized use, violation of these
              Terms, or any activity that may compromise the security of the
              platform.
            </p>
          </div>
          <div>
            <h3>4. Data Collection, Usage, and Privacy:</h3>
            <p>
              Sponsorwave collects personal data to provide and improve
              services, including your name, email, IP address, and payment
              information. We may also collect social media information, such as
              your YouTube channel, to personalize your experience on the
              platform.
              <br />
              <br />
              By using Sponsorwave, you consent to our data collection and
              processing practices as outlined in our{" "}
              <span onClick={() => navigate("/privacy-policy")}>
                Privacy Policy
              </span>
              , including essential and analytical cookies to monitor and
              enhance service quality. You may withdraw consent where feasible,
              but this may limit your ability to use some platform features.
              <br />
              <br />
              All data is handled in compliance with industry standards, and
              payment information is processed securely to meet PCI DSS (Payment
              Card Industry Data Security Standard) requirements.
            </p>
          </div>
          <div>
            <h3>5. Intellectual Property and Restrictions on Use:</h3>
            <p>
              All content on Sponsorwave, including data, insights, algorithms,
              design, logos, graphics, and text, is the property of Sponsorwave.
              You are granted a limited, non-exclusive, revocable license to
              access and use the platform for personal or commercial purposes as
              permitted by these Terms.
              <br />
              <br />
              Unauthorized activities such as scraping, reproducing,
              redistributing, or sharing our content, insights, or proprietary
              data without permission are strictly prohibited and may result in
              immediate termination of your account and legal action.
              <br />
              <br />
              Sponsorwave reserves the right to pursue legal recourse for any
              violations of our intellectual property or unauthorized use of our
              services.
            </p>
          </div>
          <div>
            <h3>6. Data Accuracy and Disclaimer of Warranties:</h3>
            <p>
              Sponsorwave provides insights based on public YouTube content and
              other available data sources. However, we do not guarantee the
              accuracy, completeness, or reliability of these insights. They are
              intended for informational purposes only and do not constitute
              professional advice.
              <br />
              <br />
              Sponsorwave disclaims all warranties, whether express or implied,
              regarding the accuracy or applicability of data insights provided
              on the platform. Users acknowledge that any reliance on such
              information is at their own risk, and Sponsorwave is not liable
              for any outcomes resulting from reliance on the insights.
            </p>
          </div>
          <div>
            <h3>7. Limitation of Liability:</h3>
            <p>
              To the fullest extent permitted by law, Sponsorwave, its
              affiliates, and its personnel are not liable for any indirect,
              incidental, special, or consequential damages, including but not
              limited to loss of profits, data, or goodwill, arising from your
              use of the platform.
              <br />
              <br />
              Sponsorwave’s total liability for any claim related to your use of
              the platform is limited to the amount you paid, if any, for
              accessing our services within the last 12 months.
            </p>
          </div>
          <div>
            <h3>8. User Conduct and Responsibilities:</h3>
            <p>As a user of Sponsorwave, you agree to:</p>
            <ul>
              <li>
                Use the platform only for lawful purposes and in accordance with
                these Terms.
              </li>
              <li>
                Avoid actions that could harm, disable, or otherwise disrupt the
                platform or interfere with other users’ enjoyment of the
                services.
              </li>
              <li>
                Refrain from using Sponsorwave for any fraudulent or malicious
                activity.
              </li>
            </ul>

            <p>
              Sponsorwave reserves the right to suspend or terminate accounts
              engaging in unlawful activities or activities that violate these
              Terms.
            </p>
          </div>
          <div>
            <h3>9. Data Retention and Government Compliance:</h3>
            <p>
              Sponsorwave retains user data as long as needed to fulfill the
              purposes stated in our Privacy Policy, typically up to one year
              following account termination, to meet legal, regulatory, or
              operational requirements.
              <br />
              <br />
              In response to legal requests or regulatory audits, Sponsorwave
              may disclose personal information as required by law. Users will
              be notified of any such disclosures to the extent permitted by
              applicable law.
            </p>
          </div>
          <div>
            <h3>10. Indemnification:</h3>
            <p>
              You agree to indemnify, defend, and hold harmless Sponsorwave and
              its affiliates, officers, and personnel from any claims,
              liabilities, damages, losses, or expenses (including legal fees)
              arising from your use of Sponsorwave, your breach of these Terms,
              or any violation of applicable laws.
            </p>
          </div>
          <div>
            <h3>11. Changes to Terms and Privacy Policy:</h3>
            <p>
              Sponsorwave reserves the right to modify these Terms and our
              Privacy Policy at any time. We will make reasonable efforts to
              notify users of significant updates, but users are encouraged to
              review these policies periodically.
              <br />
              <br />
              By continuing to use Sponsorwave after any changes to the Terms,
              you agree to be bound by the revised Terms.
            </p>
          </div>
          <div>
            <h3>12. Cookies and Tracking Technologies:</h3>
            <p>
              Sponsorwave uses cookies and other tracking technologies to
              enhance user experience and gather analytics on platform usage. By
              accessing Sponsorwave, you consent to our use of such tracking
              technologies as outlined in our{" "}
              <span onClick={() => navigate("/privacy-policy")}>
                Privacy Policy
              </span>
              .
            </p>
          </div>
          <div>
            <h3>13. Termination of Services:</h3>
            <p>
              Sponsorwave may suspend or terminate your access to the platform
              at its sole discretion for any reason, including a violation of
              these Terms, unauthorized use of intellectual property, or any
              suspected misuse or illegal activity.
              <br />
              <br />
              Upon termination, your right to access Sponsorwave and use its
              services will cease immediately. Sponsorwave reserves the right to
              pursue legal action if necessary.
            </p>
          </div>
          <div>
            <h3>14. Governing Law and Jurisdiction:</h3>
            <p>
              These Terms and your use of Sponsorwave are governed by the laws
              of [Insert Jurisdiction]. Any disputes arising from or related to
              these Terms or Sponsorwave’s services shall be resolved
              exclusively in the courts located within that jurisdiction.
            </p>
          </div>
          <div>
            <h3> 15. Payment & Refund Policy</h3>
            <p>
              All sales are final. Sponsorwave does not provide refunds for any
              subscriptions or fees paid. Users may cancel their subscription at
              any time to prevent future charges, but no refunds will be issued
              for prior payments. Our services incur costs to operate, making
              refunds impractical.
            </p>
          </div>
          <div>
            <h3> 16. Contact Information:</h3>
            <p>
              For any questions regarding these Terms or to address issues
              related to your account, please contact us at{" "}
              <span>info.sponsorwave@gmail.com.</span>
            </p>
          </div>
          <div>
            <p>
              By using Sponsorwave, you agree to these Terms of Service. If you
              do not agree with any part of these Terms, please discontinue use
              of the platform.
            </p>
          </div>
        </div>
      </div>
    </Skeleton>
  );
};

export default Tos;
