import React, { useContext, useState } from "react";

import { iChannels_info } from "../../Types/creatorStuffTypes";
import { iSponsors } from "../../Types/generalDataTypes";

import { iGlobalFts } from "../../Types/ContextGlobalVars";
import { ContextGlobalFts } from "../../Context/ContextGlobalFts";
import { ContextGlobalVars } from "../../Context/ContextGlobalVars";
import { iGlobalValues } from "../../Types/ContextGlobalVars";

// Icons
import { RiHeartFill, RiHeartLine } from "react-icons/ri";
import { MdOutlinePeopleAlt } from "react-icons/md";

import { AddCreatorToFavorites } from "../../utils/generalUtils";

import Pagination from "../pagination/pagination";

type iSearched = {
  searchPlusData: iChannels_info[] | iSponsors[];
  activePagination: number;
};

const SearchedChannelsTable = ({
  searchPlusData,
  activePagination,
}: iSearched) => {
  const [currentPage, setCurrentPage] = useState(activePagination);
  const [postPerPage] = useState(4);

  const contextFts = useContext<Partial<iGlobalFts>>(ContextGlobalFts);
  const { formatNums, fetchUserFavorites } = contextFts as iGlobalFts;

  const contextValues = useContext<Partial<iGlobalValues>>(ContextGlobalVars);
  const {
    userFavorites,
    setSelectedChannel,
    setActiveComponent,
    setActivePagination,
    setSelectedEleInfo,
  } = contextValues as iGlobalValues;

  const indexofLastPage = currentPage * postPerPage;
  const indexOfFirstPage = indexofLastPage - postPerPage;
  const currentPages = searchPlusData?.slice(indexOfFirstPage, indexofLastPage);
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const handleAddFavorite = async (channel_id: string) => {
    await AddCreatorToFavorites(channel_id, fetchUserFavorites);
  };

  return (
    <div className='scrollable-container'>
      <table className='channels_table'>
        <thead className='_table_head'>
          <tr>
            <th id='_sponsor_name'>Channels</th>
            <th>Niche</th>
            <th>Subs</th>
            <th id='_sponsor_name'>Tags/Keywords</th>
            <th>Sponsorships</th>
            <th>Add To Favorite</th>
          </tr>
        </thead>
        <tbody className='_table_body'>
          {currentPages?.map((channel: iChannels_info | iSponsors) =>
            (channel as iChannels_info).channel_id ? (
              <tr>
                <td>
                  <div
                    className='channels_first_row'
                    onClick={() => {
                      setSelectedChannel(channel as iChannels_info);
                      setSelectedEleInfo({
                        id: (channel as iChannels_info)?.channel_id,
                        type: "Channel",
                      });
                      setActiveComponent(2);
                      setActivePagination(1);
                    }}>
                    <img
                      src={(channel as iChannels_info).channel_photo}
                      alt=''
                      className='_sponsors_logos'
                    />
                    <div className='channel_names'>
                      <li> {(channel as iChannels_info).channel_name}</li>
                      <p className='_channel_username'>
                        {" "}
                        {(channel as iChannels_info).channel_username}
                      </p>
                    </div>
                  </div>
                </td>
                <td className='_tags_row'>
                  <p>{(channel as iChannels_info).channel_niche}</p>
                </td>
                <td>
                  <div className='_channel_subs'>
                    <MdOutlinePeopleAlt />
                    {formatNums(
                      Number((channel as iChannels_info).subscribers)
                    )}
                  </div>
                </td>
                <td className='_tags_row'>
                  <div className='_channel_tags'>
                    {(channel as iChannels_info).channel_tags.map((tag) => (
                      <p>{tag}</p>
                    ))}
                  </div>
                </td>
                <td className='_dashboard_pIdx'>
                  {(channel as iChannels_info).sponsored_video_count}
                </td>
                <td>
                  {userFavorites?.userFavoriteCreators?.some(
                    (fav: string) =>
                      fav === (channel as iChannels_info).channel_id
                  ) ? (
                    <RiHeartFill
                      className='_add_to_favorite'
                      onClick={() =>
                        handleAddFavorite(
                          (channel as iChannels_info).channel_id
                        )
                      }
                    />
                  ) : (
                    <RiHeartLine
                      className='_add_to_favorite'
                      onClick={() =>
                        handleAddFavorite(
                          (channel as iChannels_info).channel_id
                        )
                      }
                    />
                  )}
                </td>
              </tr>
            ) : null
          )}
        </tbody>
      </table>
      <Pagination
        postPerPage={postPerPage}
        totalPages={searchPlusData?.length}
        paginate={paginate}
        currentPage={currentPage}
        setActivePagination={setActivePagination}
      />
    </div>
  );
};

export default SearchedChannelsTable;
