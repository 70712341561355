import React, { useState, useEffect, useContext } from "react";
import "./searchPlus.css";

// The api
import { onGetSearchPlus } from "../../API/leApi";

// Types
import { iChannels_info } from "../../Types/creatorStuffTypes";
import { iSponsors } from "../../Types/generalDataTypes";

// Components
import Loader from "../../utils/Loader";
import SearchedChannelsTable from "./searchedChannelsTable";
import SearchedSponsorsTable from "./searchedSponsorsTable";

// Global Context
import { iGlobalValues } from "../../Types/ContextGlobalVars";
import { ContextGlobalVars } from "../../Context/ContextGlobalVars";

const Index = () => {
  let [isLoading, setIsLoading] = useState(false);
  let [whatIsIt, setWhatIsIt] = useState<string | null>(null);
  let [searchPlusData, setSearchPlusData] = useState<
    iChannels_info[] | iSponsors[]
  >([]);
  const [searchTerms, setSearchTerms] = useState({
    toSearch: "",
    field: "",
    sub_range: "",
    timeline: "",
  });

  const contextValues = useContext<Partial<iGlobalValues>>(ContextGlobalVars);
  const { activePagination } = contextValues as iGlobalValues;

  const handleSelectChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    field: string
  ) => {
    const { value } = event.target;
    setSearchTerms((prevTerms) => ({
      ...prevTerms,
      [field]: value,
    }));
  };

  let handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      let res = await onGetSearchPlus(searchTerms);

      if (res.status === 200) {
        let { search_result } = res.data;
        setSearchPlusData(search_result as iChannels_info[] | iSponsors[]);
      }
    } catch (error: any) {
      alert(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (searchPlusData?.length) {
      const type = (searchPlusData[0] as iSponsors)?.sponsor_id
        ? "Sponsors"
        : "Creators";
      setWhatIsIt(type);
    }
  }, [searchPlusData, setWhatIsIt]);

  return (
    <section className='_search_plus_section'>
      {isLoading && <Loader />}
      <form className='_search_containers' onSubmit={handleSubmit}>
        <select
          onChange={(e) => handleSelectChange(e, "toSearch")}
          value={searchTerms.toSearch}>
          <option value='' disabled>
            Search about...
          </option>
          <option value='Sponsor'>Sponsor</option>
          <option value='Creator'>Creator</option>
        </select>
        <input
          type='text'
          placeholder={
            searchTerms.toSearch === "Sponsor"
              ? "Industry"
              : searchTerms.toSearch === "Creator"
              ? "Niche"
              : "Industry, Niche..."
          }
          onChange={(e) =>
            setSearchTerms((prevVal) => ({
              ...prevVal,
              field: e.target.value,
            }))
          }
        />
        <select
          onChange={(e) => handleSelectChange(e, "sub_range")}
          value={searchTerms.sub_range}>
          <option value='' disabled>
            Subscribers range...
          </option>
          <option value='20K - 50K'>..K - 50K</option>
          <option value='50K - 100K'>50K - 100K</option>
          <option value='100K - 500K'>100K - 500K</option>
          <option value='500K - ...K'>500K - ...K</option>
          <option value='All ranges'>All ranges</option>
        </select>
        <select
          onChange={(e) => handleSelectChange(e, "timeline")}
          value={searchTerms.timeline}>
          <option value='' disabled>
            Timeline...
          </option>
          <option value='Last Month'>Last Month</option>
          <option value='Last 3 Month'>Last 3 Month</option>
          <option value='Last 6 Month'>Last 6 Month</option>
          <option value='Last Year'>Past Year</option>
        </select>
        <button className='_search_plus_btn'>Search</button>
      </form>
      <div className='_res_container'>
        {searchPlusData?.length > 0 ? (
          whatIsIt === "Creators" ? (
            <SearchedChannelsTable
              searchPlusData={searchPlusData}
              activePagination={activePagination}
            />
          ) : (
            <SearchedSponsorsTable
              searchPlusData={searchPlusData}
              activePagination={activePagination}
            />
          )
        ) : (
          <p className='_no_res_text'>
            Choose whether you want to search for sponsors or creators, then
            enter relevant keywords like industry or niche.{" "}
          </p>
        )}
      </div>
    </section>
  );
};

export default Index;
