import React, { useContext } from "react";

import { iGlobalValues } from "../../Types/ContextGlobalVars";
import { ContextGlobalVars } from "../../Context/ContextGlobalVars";

import SponsorDetails from "./sponsorDetails";
import LaTable from "../Table/LaTable";

const Index = () => {
  const contextValues = useContext<Partial<iGlobalValues>>(ContextGlobalVars);
  const {
    selectedSponsor,
    setSelectedSponsor,
    activePagination,
    setSelectedEleInfo,
  } = contextValues as iGlobalValues;

  return (
    <section className='_sponsors_section'>
      {selectedSponsor ? (
        <SponsorDetails
          selectedSponsor={selectedSponsor}
          onBack={() => {
            setSelectedSponsor(null);
            setSelectedEleInfo({
              id: "",
              type: "",
            });
          }}
        />
      ) : (
        <LaTable howMany={7} yes={23} activePagination={activePagination} />
      )}
    </section>
  );
};

export default Index;
