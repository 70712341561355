import React from "react";
import "./App.css";

//RoutersFile
import LaRoutes from "./Routes";
import { AuthProvider } from "./Context/AuthProvider";
import { GlobalValuesProvider } from "./Context/ContextGlobalVars";
import { GlobalFts } from "./Context/ContextGlobalFts";

import ReactGA from "react-ga4";
ReactGA.initialize("G-BVRQT2SHLP");
ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname,
});

const App = () => {
  return (
    <div className='App'>
      <AuthProvider>
        <GlobalValuesProvider>
          <GlobalFts>
            <LaRoutes />
          </GlobalFts>
        </GlobalValuesProvider>
      </AuthProvider>
    </div>
  );
};

export default App;
