import React, { useContext, useState, useRef, useEffect } from "react";
import "./dashboardHome.css";

import Logo from "../../Assets/_sponsorwave.png";
import UserAvatar from "../../Assets/UserAvatar.png";
import useLogout from "../../Hooks/useLogout";

import { FiChevronRight, FiSearch, FiX } from "react-icons/fi";
import { LuLogOut } from "react-icons/lu";
import { RiUserSettingsLine } from "react-icons/ri";

import HomeBody from "../HomeBody/index";
import Sponsors from "../Sponsors/index";
import Youtubers from "../YouTubers/index";
import SearchPlus from "../SearchPlus/index";
import MyFavorites from "../Favorites/index";

import { iGlobalValues } from "../../Types/ContextGlobalVars";
import { ContextGlobalVars } from "../../Context/ContextGlobalVars";
import { iSponsors } from "../../Types/generalDataTypes";
import { iChannels_info } from "../../Types/creatorStuffTypes";

const Index = () => {
  const [isFocused, setIsFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResult, setSearchResult] = useState<
    (iSponsors | iChannels_info)[] | []
  >([]);

  const secondSearchBarRef = useRef<HTMLInputElement>(null);

  const contextValues = useContext<Partial<iGlobalValues>>(ContextGlobalVars);
  const {
    activeComponent,
    setActiveComponent,
    setSelectedChannel,
    setSelectedSponsor,
    setSelectedEleInfo,
    setActivePagination,
    YouTubers,
    sponsors,
    getUserData,
  } = contextValues as iGlobalValues;

  const heighLightEle = (idx: number) => {
    setActiveComponent(idx); // Update active component index
    setActivePagination(1);
  };

  useEffect(() => {
    if (isFocused && secondSearchBarRef.current) {
      secondSearchBarRef.current.focus();
    }
  }, [isFocused]);

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case 0:
        return <HomeBody />;
      case 1:
        return <Sponsors />;
      case 2:
        return <Youtubers />;
      case 3:
        return <SearchPlus />;
      case 4:
        return <MyFavorites />;
      default:
        return <HomeBody />; // Default to HomeBody if no valid index is found
    }
  };

  const handleSearchItem = (le_search_term: string) => {
    setSearchTerm(le_search_term);
    let searchRes = YouTubers?.filter((y) =>
      y?.channel_name
        ?.toLocaleLowerCase()
        ?.includes(le_search_term.toLocaleLowerCase())
    );
    let sponsorSearch = sponsors?.filter((s) =>
      s?.sponsor_name
        ?.toLocaleLowerCase()
        ?.includes(le_search_term.toLocaleLowerCase())
    );

    // This to combine the search result
    let combinedResults = [...searchRes, ...sponsorSearch];
    setSearchResult(combinedResults);
  };

  return (
    <main className='_dashboard'>
      <div className='_dashboard_sidebar'>
        <div>
          <img src={Logo} alt='dashboard_logo' className='_dashboard_logo' />
          <ul className='_dashboard_menu'>
            <li
              onClick={() => heighLightEle(0)}
              className={
                activeComponent === 0
                  ? "_dashboard_menu_ele _menu_li_active"
                  : "_dashboard_menu_ele"
              }>
              Home <FiChevronRight />
            </li>
            <li
              onClick={() => heighLightEle(1)}
              className={
                activeComponent === 1
                  ? "_dashboard_menu_ele _menu_li_active"
                  : "_dashboard_menu_ele"
              }>
              Sponsors <FiChevronRight />
            </li>
            <li
              onClick={() => heighLightEle(2)}
              className={
                activeComponent === 2
                  ? "_dashboard_menu_ele _menu_li_active"
                  : "_dashboard_menu_ele"
              }>
              YouTubers <FiChevronRight />
            </li>
            <li
              onClick={() => heighLightEle(3)}
              className={
                activeComponent === 3
                  ? "_dashboard_menu_ele _menu_li_active"
                  : "_dashboard_menu_ele"
              }>
              Search+ <FiChevronRight />
            </li>
            <li
              onClick={() => heighLightEle(4)}
              className={
                activeComponent === 4
                  ? "_dashboard_menu_ele _menu_li_active"
                  : "_dashboard_menu_ele"
              }>
              My Favorites <FiChevronRight />
            </li>
            <div className='_SMK_div'>
              <li className='_dashboard_menu_ele'>
                Smart Media Kit <FiChevronRight />
              </li>
              <span className='_coming_soon'>coming soon</span>
            </div>
          </ul>
        </div>

        <button className='_dashboard_logout_btn' onClick={useLogout()}>
          Logout <LuLogOut />
        </button>
      </div>
      <div className='_dashboard_content'>
        <div className='_dashboard_header'>
          {activeComponent === 3 ? (
            <div className='_search_plus_title_container'>
              <h1 className='_search_plus_title'>Search Plus...</h1>
              <p className='_search_plus_text'>
                Discover the unknowns collaborations of Youtube sean.
              </p>
            </div>
          ) : (
            <div className='_header_search'>
              <FiSearch />
              <input
                type='text'
                placeholder='creator, sponsor etc...'
                onFocus={() => setIsFocused(true)}
              />
            </div>
          )}
          {isFocused && (
            <>
              <div className='_header_search_div'></div>
              <div className='_header_search_focused'>
                <FiX
                  className='_focused_search_close_icon'
                  onClick={() => {
                    setSearchTerm("");
                    setIsFocused(false);
                  }}
                />
                <div className='_search_focused_input_container'>
                  <FiSearch />
                  <input
                    type='text'
                    placeholder='creator, sponsor etc...'
                    onChange={(e) => handleSearchItem(e.target.value)}
                    ref={secondSearchBarRef} // Directly use the ref here
                  />
                </div>
                {isFocused && searchTerm ? (
                  <div className='_search_res_container'>
                    {searchResult && searchResult?.length > 0 ? (
                      searchResult?.map(
                        (v: iSponsors | iChannels_info, index: number) =>
                          (v as iChannels_info)?.channel_name ? (
                            <p
                              key={index}
                              className='_searched_name'
                              onClick={() => {
                                setIsFocused(false);
                                setSelectedChannel(v as iChannels_info);
                                setSelectedEleInfo({
                                  id: (v as iChannels_info)?.channel_id,
                                  type: "Channel",
                                });
                                setSearchTerm("");
                                setActiveComponent(2);
                                setActivePagination(1);
                              }}>
                              {(v as iChannels_info)?.channel_name}
                              <span>(creator)</span>
                            </p>
                          ) : (
                            <p
                              key={index}
                              className='_searched_name'
                              onClick={() => {
                                setIsFocused(false);
                                setSelectedSponsor(v as iSponsors);
                                setSelectedEleInfo({
                                  id: (v as iSponsors)?.sponsor_id,
                                  type: "Sponsor",
                                });
                                setSearchTerm("");
                                setActiveComponent(1);
                                setActivePagination(1);
                              }}>
                              {(v as iSponsors)?.sponsor_name}
                              <span>(sponsor)</span>
                            </p>
                          )
                      )
                    ) : (
                      <div>No results found</div>
                    )}
                  </div>
                ) : (
                  <p className='_search_prompt'>Type to search</p>
                )}
              </div>
            </>
          )}
          <div className='_dashboard_user'>
            <>
              <img
                src={
                  getUserData.profile_image
                    ? getUserData.profile_image
                    : UserAvatar
                }
                alt='user_image'
                className='_user_image'
              />
              <div className='_profile_text'>
                <h5>{getUserData.user_name}</h5>
                <span className='_dashboard_username'>
                  <RiUserSettingsLine className='_profile_icon' />
                  <p>profile</p>
                </span>
              </div>
            </>
          </div>
        </div>
        <div className='_dashboard_body'>{renderActiveComponent()}</div>
      </div>
    </main>
  );
};

export default Index;
