import React, { useEffect, useState, useContext } from "react";

// API
import { onGetSponsorsAndSponsoredVideos } from "../../API/leApi";

//Icons
import { FaYoutube } from "react-icons/fa";
import { MdOutlinePeopleAlt } from "react-icons/md";
import { RiHeartFill, RiHeartLine } from "react-icons/ri";
import { FaMoneyBills } from "react-icons/fa6";
import { MdOutlineKeyboardBackspace } from "react-icons/md";

// Types
import { iChannels_info } from "../../Types/creatorStuffTypes";
import { iSponsoredVideos, iSponsors } from "../../Types/generalDataTypes";

// Global Context
import { iGlobalValues } from "../../Types/ContextGlobalVars";
import { ContextGlobalVars } from "../../Context/ContextGlobalVars";
import { ContextGlobalFts } from "../../Context/ContextGlobalFts";
import { iGlobalFts } from "../../Types/ContextGlobalVars";

// Functions
import { AddCreatorToFavorites } from "../../utils/generalUtils";

interface iChannelDetail {
  channel: iChannels_info;
  onBack: () => void;
}

interface iInfo {
  sponsors: iSponsors[] | null;
  sponsored_videos: iSponsoredVideos[] | null;
}

const ChannelDetails = ({ channel, onBack }: iChannelDetail) => {
  const [getInfo, setGetInfo] = useState<iInfo>({
    sponsored_videos: [],
    sponsors: [],
  });

  const contextValues = useContext<Partial<iGlobalValues>>(ContextGlobalVars);
  const {
    setActiveComponent,
    setSelectedSponsor,
    setActivePagination,
    setSelectedEleInfo,
    userFavorites,
  } = contextValues as iGlobalValues;
  const contextFts = useContext<Partial<iGlobalFts>>(ContextGlobalFts);
  const { fetchUserFavorites } = contextFts as iGlobalFts;

  const handleGetSponsorsVideos = async () => {
    try {
      let res = await onGetSponsorsAndSponsoredVideos(channel.channel_id);
      console.log("res of sponsored videos: ", res);

      if (res.status === 200) {
        setGetInfo({
          sponsored_videos: res.data.sponsored_videos,
          sponsors: res.data.sponsors,
        });
      }
    } catch (error: any) {
      alert(error.response?.data?.message);
    }
  };

  useEffect(() => {
    handleGetSponsorsVideos();
  }, []);

  const handleAddFavorite = async (channel_id: string) => {
    await AddCreatorToFavorites(channel_id, fetchUserFavorites);
  };

  return (
    <section className='_channel_details_section'>
      <button onClick={onBack} className='_back_btn'>
        <MdOutlineKeyboardBackspace />
        Back to List
      </button>
      <div className='_creator_details'>
        <div className='_details_text'>
          <div className='_img_text_details'>
            <img
              src={channel.channel_photo}
              alt={channel.channel_name}
              className='_channel_img'
            />
            <span className='_details_name'>
              <h3>{channel.channel_name}</h3>
              <p>{channel.channel_username}</p>
            </span>
            <div>
              <div>
                {userFavorites?.userFavoriteCreators?.some(
                  (fav: string) => fav === channel.channel_id
                ) ? (
                  <div>
                    <RiHeartFill
                      className='_add_to_favorite _detail_icon'
                      onClick={() => handleAddFavorite(channel.channel_id)}
                    />
                  </div>
                ) : (
                  <div>
                    <RiHeartLine
                      className='_add_to_favorite _detail_icon'
                      onClick={() => handleAddFavorite(channel.channel_id)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <span className='_middle_stats_containers'>
            <p>Subscribers:</p>
            <p className='_d_subs_spon_vids'>
              <MdOutlinePeopleAlt />
              {Number(channel.subscribers).toLocaleString()}
            </p>
          </span>
          <span className='_middle_stats_containers'>
            <p>Sponsored Videos:</p>
            <p className='_num_of_spon_vids'>
              <FaMoneyBills /> {Number(channel.sponsored_video_count)}
            </p>
          </span>
        </div>
        <div className='_channel_link'>
          <span>
            <p className='_niche'>Niche:</p>
            <p className='_channel_niche'>{channel.channel_niche}</p>
          </span>
          <a
            href={`https://www.youtube.com/channel/${channel.channel_id}`}
            rel='noreferrer'
            target='_blank'>
            <FaYoutube className='_youtube_icon' />
          </a>
        </div>
      </div>
      <div className='_video_sponsors_sec'>
        <div className='_has_worked_with_container'>
          <h4 className='_sVideos_title'>Has Worked With In The Past Year:</h4>
          {getInfo.sponsors?.map((sponsor) => (
            <div
              key={sponsor.sponsor_id}
              onClick={() => {
                setSelectedSponsor(sponsor);
                setSelectedEleInfo({
                  id: sponsor.sponsor_id,
                  type: "Sponsor",
                });
                setActiveComponent(1);
                setActivePagination(1);
              }}>
              <img src={sponsor.sponsor_logo} alt='' />
              <p>{sponsor.sponsor_name}</p>
            </div>
          ))}
        </div>
        <div className='_sponsored_videos_container'>
          <h4 className='_sVideos_title'>Sponsored Videos In The Past Year:</h4>
          <div className='sV_container'>
            {getInfo.sponsored_videos
              ?.filter((vid) => vid.sponsor_logo) // Filter to include only videos with sponsor logos
              ?.sort((a, b) => Number(b.published_at) - Number(a.published_at)) // Sort by published date (newest first)
              // Then loop through them to display on the screen
              .map((vid, idx) => (
                <div
                  className='_sponsored_video_container'
                  key={`${vid.video_id}-${idx}`}>
                  <div className='_sponsored_video_info'>
                    <div className='_sponsored_video_text'>
                      <p>Sponsored by: </p>
                      <span className='_sponsor_img_info_span'>
                        <img
                          src={vid.sponsor_logo}
                          alt=''
                          className='_sponsored_sponsor_img'
                        />
                        <p>{vid.sponsor_name}</p>
                      </span>
                    </div>
                    <p className='_date_de_sponsor'>
                      {new Date(Number(vid.published_at)).toLocaleDateString(
                        "en-US",
                        {
                          day: "2-digit",
                          month: "long",
                          year: "numeric",
                        }
                      )}
                    </p>
                  </div>
                  <div className='_sponsored_video_img_desc'>
                    <div>
                      <a
                        href={`https://www.youtube.com/watch?v=${vid.video_id}`}
                        target='_blank'
                        rel='noreferrer'>
                        <img
                          src={vid.video_thumbnail}
                          alt='video_thumbnail'
                          className='_sponsored_video_thumbnail'
                        />
                      </a>
                    </div>
                    <div className='_title_and_desc'>
                      <h6 className='_sVideo_title'>{vid.video_title}</h6>
                      <p className='_sVideo_desc'>
                        {vid.video_description.length > 200
                          ? vid.video_description.substring(0, 200) + "..."
                          : vid.video_description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChannelDetails;
