import React, { useState } from "react";

import Header from "../Components/TheHeader/index";
import Footer from "../Components/Footer/index";

import Loader from "../utils/Loader";
import { onSendWaiterInfo } from "../API/leApi";

const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const USER_NAME_REGEX = /^[a-zA-Z0-9_-]+(?: [a-zA-Z0-9_-]+)*$/;

const WaitList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [waiterInfo, setWaiterInfo] = useState({
    name: "",
    email: "",
  });

  // to check if the email is valid
  const validateEmail = (email: string) => {
    return EMAIL_REGEX.test(email);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!waiterInfo.email || !waiterInfo.name) {
      setErrMsg("Please fill in all fields.");
      return;
    }

    if (!validateEmail(waiterInfo.email)) {
      setErrMsg("Please enter a valid email address.");
      return;
    }

    if (!USER_NAME_REGEX.test(waiterInfo.name)) {
      setErrMsg(
        "Name must be 3-15 characters long and can contain only letters."
      );
      return;
    }

    setIsLoading(true);
    try {
      const res = await onSendWaiterInfo(waiterInfo);

      if (res.status === 200) {
        setIsLoading(false);
        alert(
          "Thanks for joining the waiting list. Keep an eye on your inbox, we will email you soon!"
        );
        setWaiterInfo({
          email: "",
          name: "",
        });
      }
    } catch (error: any) {
      if (error.response) alert(`${error.response.data.message} `);
    }
  };

  return (
    <>
      <Header />
      {isLoading && <Loader />}
      <div className='_wait_list_container'>
        <div className='_waitlistt_headline'>
          <h2>
            Exclusive <span>50%</span> Discount for Early Birds—Join the
            Waitlist Today!
          </h2>
          <p>
            Join our waiting list and be the first to access Sponsorwave at half
            the price! Find perfect sponsors and unlock YouTube sponsorship
            deals effortlessly—Get Ready to Boost Your Sponsorship Deals!
          </p>
        </div>
        <form action='' className='_waiting_form' onSubmit={handleSubmit}>
          <input
            type='text'
            placeholder='_name'
            value={waiterInfo.name}
            onChange={(e) => {
              setErrMsg("");

              setWaiterInfo((preVal) => ({
                ...preVal,
                name: e.target.value,
              }));
            }}
          />
          <input
            type='email'
            placeholder='_email'
            value={waiterInfo.email}
            onChange={(e) => {
              setErrMsg("");

              setWaiterInfo((preVal) => ({
                ...preVal,
                email: e.target.value,
              }));
            }}
          />
          {errMsg && <p className='_inputsErrMsg'>{errMsg}</p>}
          <button type='submit'>Join The Waitlist</button>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default WaitList;
