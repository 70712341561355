import React, { useState, useContext } from "react";

import Pagination from "../pagination/pagination";
import { iGlobalValues } from "../../Types/ContextGlobalVars";
import { ContextGlobalVars } from "../../Context/ContextGlobalVars";
import ChannelsTable from "./channelsTable";
import ChannelDetails from "./channelDetail";

const Index = () => {
  const contextValues = useContext<Partial<iGlobalValues>>(ContextGlobalVars);
  const {
    YouTubers,
    selectedChannel,
    setSelectedChannel,
    setActivePagination,
    setSelectedEleInfo,
    activePagination,
  } = contextValues as iGlobalValues;

  const [currentPage, setCurrentPage] = useState(activePagination);
  const [postPerPage] = useState(6);

  const indexofLastPage = currentPage * postPerPage;
  const indexOfFirstPage = indexofLastPage - postPerPage;
  const currentPages = YouTubers?.slice(indexOfFirstPage, indexofLastPage);
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <>
      {selectedChannel ? (
        <ChannelDetails
          channel={selectedChannel}
          onBack={() => {
            setSelectedChannel(null);
            setSelectedEleInfo({
              id: "",
              type: "",
            });
          }}
        />
      ) : (
        <div>
          <table className='channels_table'>
            <thead className='_table_head'>
              <tr>
                <th id='_sponsor_name'>Channels</th>
                <th>Niche</th>
                <th>Subs</th>
                <th id='_sponsor_name'>Tags/Keywords</th>
                <th>Sponsorships</th>
              </tr>
            </thead>
            <tbody className='_table_body'>
              {currentPages?.map((creator) => {
                return (
                  <ChannelsTable
                    creator={creator}
                    key={creator.channel_id}
                    onClick={() => {
                      setSelectedChannel(creator);
                      setSelectedEleInfo({
                        id: creator.channel_id,
                        type: "Channel",
                      });
                    }}
                  />
                );
              })}
            </tbody>
          </table>
          <Pagination
            postPerPage={postPerPage}
            totalPages={YouTubers?.length}
            paginate={paginate}
            currentPage={currentPage}
            setActivePagination={setActivePagination}
          />
        </div>
      )}
    </>
  );
};

export default Index;
