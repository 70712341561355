import {
  onAddToFavoritesOrRemoveCreators,
  onAddToFavoritesOrRemoveSponsors,
} from "../API/leApi";

// Refactor AddToFavorite to be a regular function
const AddCreatorToFavorites = async (
  channel_id: string,
  fetchUserFavorites: () => Promise<void>
) => {
  try {
    const res = await onAddToFavoritesOrRemoveCreators(channel_id);
    if (res.status === 200) {
      await fetchUserFavorites();
    }
  } catch (error: any) {
    alert(error.response?.data?.message);
  }
};

const AddSponsorToFavorites = async (
  sponsor_id: string,
  fetchUserFavorites: () => Promise<void>
) => {
  try {
    const res = await onAddToFavoritesOrRemoveSponsors(sponsor_id);
    if (res.status === 200) {
      await fetchUserFavorites();
    }
  } catch (error: any) {
    alert(error.response?.data?.message);
  }
};

export { AddCreatorToFavorites, AddSponsorToFavorites };
