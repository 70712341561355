import React, { useContext, useEffect } from "react";
import "./PagesStyle.css";

// Components
import Pricing from "../Components/Pricing/index";
import Skeleton from "../utils/skeleton";
import EmailUsageNotify from "../Components/GeneralUse/emailUsageNotify";

// Context
import { ContextGlobalVars } from "../Context/ContextGlobalVars";
import { iGlobalValues } from "../Types/ContextGlobalVars";
import { useAuth } from "../Context/AuthProvider";

const ChosePlan = () => {
  const contextValues = useContext<Partial<iGlobalValues>>(ContextGlobalVars);
  const { closeNotice, setCloseNotice } = contextValues as iGlobalValues;

  const { state } = useAuth();
  useEffect(() => {
    !state.hasUserPaid && !state.customer_id
      ? setCloseNotice(true)
      : setCloseNotice(false);
  }, []);

  return (
    <Skeleton>
      <div className='_chose_plan'>
        <div className='_chose_plan_container'>
          {closeNotice && (
            <>
              <EmailUsageNotify />
              <div className='_bg_drop'></div>
            </>
          )}
          <Pricing />
        </div>
      </div>
    </Skeleton>
  );
};

export default ChosePlan;
