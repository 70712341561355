import React, { useContext, createContext, useEffect } from "react";

import { onGetUserFavorites } from "../API/leApi";
import { iGlobalFts, iGlobalValues } from "../Types/ContextGlobalVars";
import { ContextGlobalVars } from "./ContextGlobalVars";

const ContextGlobalFts = createContext<iGlobalFts | {}>({});

const GlobalFts: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const contextValues = useContext<Partial<iGlobalValues>>(ContextGlobalVars);
  const { setUserFavorites } = contextValues as iGlobalValues;

  const fetchUserFavorites = async () => {
    try {
      let res = await onGetUserFavorites();

      if (res.status === 200) {
        let { user_favorite_sponsors, user_id, user_favorites_creators } =
          res.data;
        setUserFavorites({
          userFavoriteSponsors: user_favorite_sponsors as string[],
          userFavoriteCreators: user_favorites_creators as string[],
          user_id: user_id,
        });
      }
    } catch (error: any) {
      // alert(error.response?.data?.message);
    }
  };

  const formatNums = (num: number): string => {
    if (num >= 1_000_000) {
      return (Math.round(num / 100_000) / 10).toFixed(1) + "M";
    } else if (num >= 1_000) {
      return (Math.round(num / 100) / 10).toFixed(1) + "K";
    }
    return num?.toString(); // For numbers below 1000, return the original number
  };

  useEffect(() => {
    fetchUserFavorites();
  }, []);

  return (
    <ContextGlobalFts.Provider value={{ fetchUserFavorites, formatNums }}>
      {children}
    </ContextGlobalFts.Provider>
  );
};

export { GlobalFts, ContextGlobalFts };
